import {
    createContext,
    default as React,
    Dispatch,
    ReactNode,
    SetStateAction,
    useContext,
    useEffect,
    useState,
} from 'react'
import DayJS from '../../../helpers/DayJS'
import { toApiDate } from '../../../helpers/formatter/dateFormatters'
import { DateFilterProps } from '../api-reports'
import { MeetLauncher } from '../Components/MeetLauncher'
import { ModalMentors } from '../Components/ModalMentors'
import { useCohorts } from '../Hooks/useCohorts'
import Reports from '../Reports'
import { SelectedOptions } from '../Reports/Components/CohortsSelect'
import { useTeam } from './TeamContext'

const Context = createContext({} as ReportsContext)
Context.displayName = 'ReportsContext'

export const useReports = () => useContext<ReportsContext>(Context)

export const ReportsContextProvider = ({ children = <Reports /> }: { children?: ReactNode }) => {
    const { courseID } = useTeam()
    const { cohorts } = useCohorts()
    const reportMenuTab = useState<ReportsActions>('users')

    // Filters on Reports
    const [optionsSelected, setOptionsSelected] = useState<SelectedOptions | null>(null)
    const [activePeriod, setActivePeriod] = useState<number>(0)
    const defaultDate = DayJS().format('YYYY-MM-DD')
    const [reportQuery, setReportQuery] = useState<DateFilterProps>({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        courseId: courseID!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        cohorts: cohorts!,
        from: defaultDate,
        to: defaultDate,
    })

    const enabled = !!courseID && !!cohorts && !!reportQuery.from && !!reportQuery.to
    const staleTime = 1000 * 60 * 60

    const setReportQueryFn = (data: Partial<DateFilterProps>) => {
        setReportQuery((prev) => ({
            ...prev,
            ...data,
        }))
    }

    // Set cohorstSelected when selectedOptions change
    useEffect(() => {
        if (optionsSelected) {
            if (optionsSelected?.length === 0) setReportQueryFn({ cohorts })
            else
                setReportQueryFn({
                    cohorts: optionsSelected.map(({ value }) => toApiDate(`${value}`)),
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [optionsSelected])

    useEffect(() => {
        setReportQueryFn({ cohorts })
    }, [cohorts])

    return (
        <Context.Provider
            value={{
                reportMenuTab,
                optionsSelected,
                setOptionsSelected,
                reportQuery,
                setReportQueryFn,
                activePeriod,
                setActivePeriod,
                enabled,
                staleTime,
            }}
        >
            {children}
            <MeetLauncher />
            <ModalMentors />
        </Context.Provider>
    )
}

// Interface
interface ReportsContext {
    reportMenuTab: [ReportsActions, Dispatch<SetStateAction<ReportsActions>>]
    optionsSelected: SelectedOptions
    setOptionsSelected: Dispatch<SetStateAction<SelectedOptions>>
    reportQuery: DateFilterProps
    setReportQueryFn: (data: Partial<DateFilterProps>) => void
    activePeriod: number
    setActivePeriod: Dispatch<SetStateAction<number>>
    enabled: boolean
    staleTime: number
}

export type ReportsActions = 'users' | 'participation' | 'valuation' | 'surveys' | 'cooperation'
