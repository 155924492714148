import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import LoadingPage from '../../../Other/LoadingPage'
import { useCsvReport } from '../../Hooks/useCsvReport'
import { useUsers } from '../../Hooks/useUsers'
import { colors } from '../../Reports'
import { ButtonExport } from './ButtonExport'
import { ChartComponent, NoData } from './ChartComponent'
import { getUsersCsv } from '../../api-reports'

export const UsersReports = () => {
    const { t } = useTranslation('teamReports')
    const { getReport } = useCsvReport()

    const { red, lightGrey, violet, lightBlue, blue, yellow } = colors

    const { usersGraph, isLoading } = useUsers()

    const { barChart, pieChart } = usersGraph || {}

    const barChartColors = [lightBlue, yellow, blue, red, violet]

    const transformBarChart = () => {
        if (!barChart) return null
        const header = [
            'status',
            'total',
            { role: 'style' },
            {
                sourceColumn: 0,
                role: 'annotation',
                type: 'string',
                calc: 'stringify',
            },
        ]

        const transformedData = barChart.map((row, index) => {
            if (index === 0) {
                return header
            } else {
                const status = t(row[0] as string)
                const total = row[1] as number
                const color = barChartColors[index - 1]
                const value = row[1]?.toString() ?? ''
                return [status, total, color, value]
            }
        })

        return transformedData
    }

    const translatePieChart = () => {
        if (!pieChart) return null
        const header = ['status', 'total']

        const transformedData = pieChart.map((row, index) => {
            if (index === 0) {
                return header
            } else {
                const status = t(row[0] as string)
                const total = row[1] as number
                return [status, total]
            }
        })

        return transformedData
    }

    if (isLoading) return <LoadingPage />

    return (
        <UsersReportStyles>
            {!barChart || !pieChart ? (
                <NoData text="No data to render yet" />
            ) : (
                <>
                    <header className="report-header">
                        <h4>{t('Participants')}</h4>
                        <ButtonExport
                            onClick={() => getReport(getUsersCsv, 'cohorts')}
                            disabled={!barChart && !pieChart}
                        />
                    </header>
                    <div className="report-display">
                        <ChartComponent
                            chartType="BarChart"
                            data={transformBarChart()}
                            options={{
                                legend: { position: 'none' },
                                chartArea: {
                                    left: 100,
                                    top: 20,
                                    right: 20,
                                    bottom: 20,
                                    width: '100%',
                                    height: '100%',
                                },
                                hAxis: {
                                    textPosition: 'none',
                                    gridlines: { color: 'transparent' },
                                },
                                vAxis: { gridlines: { color: 'transparent' } },
                            }}
                        />
                        <ChartComponent
                            chartType="PieChart"
                            data={translatePieChart()}
                            options={{
                                pieHole: 0.4,
                                pieSliceTextStyle: {
                                    fontSize: 12,
                                },
                                colors: [lightGrey, yellow, red],
                                chartArea: {
                                    left: 20,
                                    top: 40,
                                    right: 20,
                                    bottom: 60,
                                },
                                legend: { position: 'bottom' },
                            }}
                        />
                    </div>
                </>
            )}
        </UsersReportStyles>
    )
}

const UsersReportStyles = styled.section`
    & > .report-display {
        display: grid;
        grid-template-columns: 70% 30%;
        gap: 1em;
        & > section {
            display: flex;
            flex-direction: column;
            justify-content: center;
            & > * {
                border-bottom: none;
            }
        }
        & .chart {
            width: '100%';
            & > * {
                text-transform: capitalize;
            }
        }
    }
    @media (max-width: 1140px) {
        & > .report-content {
            flex-direction: column;
        }
    }
`
