import React, { useEffect, useRef, useState, useContext } from 'react'
import CollabEditView from './CollabEditView'
import { useRoute } from 'wouter'

import firebase from '../../../../firebase'
import CodeMirror from 'codemirror'

import UserContext from '../../../../context/UserContext'

import Axios from 'axios'
import Config from '../../../../config'
import { FirepadUserList } from './firepad-users'
// import Firepad from '../../../../helpers/firepad'

import { SubjectStudentContext } from '../../../../context/SubjectStudentContext'
import { UIContext } from '../../../../context/UIContext'

import CollabContextProvider from '../../../../context/CollabContext'
import LoadingPage from '../../../Other/LoadingPage'
import { useTranslation } from 'react-i18next'

import { jsPDF } from 'jspdf'

const CollabEditContainer = () => {
    const [, params] = useRoute('/team/:id/subject/:sid/collab/edit/:docId')
    const { t } = useTranslation(['teamCollab'])
    const { teamId, subject, professorSchedule, mainLinks, teamData } = useContext(
        SubjectStudentContext
    )
    const { selectedDoc } = useContext(CollabContextProvider)
    const { user } = useContext(UserContext)

    const fpad = useRef(null)
    const [title] = useState(t('item.title'))
    const [ready] = useState(true)

    const { setInfoBlock } = useContext(UIContext)

    const onTitleSave = async (title) => {
        try {
            await Axios.patch(`${Config.API}/v2/document/${params.docId}`, {
                title,
            })
            setInfoBlock(['success', t('status.save-successfully')])
            setTimeout(() => setInfoBlock([]), 3000)
        } catch (error) {
            setInfoBlock([
                'error',
                error.message || (error.data && error.data.message) || t('status.save-error'),
            ])
            setTimeout(() => {
                setInfoBlock([])
            }, 3000)
        }
    }

    const createNew = async () => {
        // setReady(false)

        fpad.current.childNodes.length > 0 &&
            document.getElementById('fpad-container').childNodes[0].remove()

        const codeMirror = CodeMirror(fpad.current, {
            lineWrapping: true,
        })

        window.CodeMirror = CodeMirror

        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const Firepad = require('firepad')
        window.Firepad = Firepad
        const firepadRef = firebase.database().ref()
        const childRef = firepadRef.child(`docs`).push()
        const childRefKey = childRef.key

        // Guardar en la API
        // Instance of firepad added to fpad ref child.
        const fpadInstance = Firepad.fromCodeMirror(childRef, codeMirror, {
            richTextShortcuts: true,
            richTextToolbar: true,
            defaultText: 'Hello, World!',
        })

        window.fpadInstance = fpadInstance

        FirepadUserList.fromDiv(
            firepadRef.child('users'),
            document.getElementById('userlist'),
            user._id,
            user.name
        )

        await Axios.post(`${Config.API}/v2/document`, {
            _teamId: teamId,
            document: childRefKey,
            title: title,
            description: ' ',
        })

        window.history.replaceState('', 'title', childRefKey)
        // setReady(true)
    }

    const editExistent = () => {
        fpad.current.childNodes.length > 0 &&
            document.getElementById('fpad-container').childNodes[0].remove()
        const codeMirror = CodeMirror(fpad.current, {
            lineWrapping: true,
        })
        window.CodeMirror = CodeMirror
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const Firepad = require('firepad')
        const firepadRef = firebase.database().ref(`/docs/${params.docId}`)
        const fpadInstance = Firepad.fromCodeMirror(firepadRef, codeMirror, {
            richTextShortcuts: true,
            richTextToolbar: true,
            userId: user._id,
        })
        window.fpadInstance = fpadInstance

        FirepadUserList.fromDiv(
            firepadRef.child('users'),
            document.getElementById('userlist'),
            user._id,
            user.name
        )
    }

    const downloadPdf = () => {
        const doc = new jsPDF()
        const html = window.fpadInstance.getText()

        let wrapped = doc.splitTextToSize(html, 180)
        //var pageHeight = doc.internal.pageSize.height;
        // doc.setFontType("normal");
        // doc.setFontSize("11");
        let y = 15
        for (let i = 0; i < wrapped.length; i++) {
            if (y > 250) {
                y = 15
                doc.addPage()
            }
            doc.text(15, y, wrapped[i])
            y = y + 7
        }
        doc.save(`${selectedDoc?.title ?? t('item.title')}.pdf`)
    }

    useEffect(() => {
        if (params.docId === 'new') {
            createNew()
        } else {
            editExistent()
        }

        return () => {
            window.fpadInstance.dispose()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (!ready) return <LoadingPage />

    return (
        <CollabEditView
            mainLinks={mainLinks}
            fpad={fpad}
            onTitleSave={onTitleSave}
            teamId={teamId}
            subject={subject}
            professorSchedule={professorSchedule}
            selectedDoc={selectedDoc}
            downloadPdf={downloadPdf}
            teamData={teamData}
        />
    )
}

export default CollabEditContainer
