import { useQuery } from 'react-query'
import { getCohorts } from '../api-reports'
import { useTeam } from '../Context/TeamContext'

export const useCohorts = () => {
    const { courseID: courseId } = useTeam()
    const { data: cohorts, ...rest } = useQuery(
        [courseId, 'Reports', 'Cohorts'],
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        () => getCohorts(courseId!),
        {
            enabled: !!courseId,
            staleTime: 1000 * 60 * 5,
        }
    )
    return { cohorts, ...rest }
}
