import DayJS from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import duration from 'dayjs/plugin/duration'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'

DayJS.extend(timezone)
DayJS.extend(utc)
DayJS.extend(duration)
DayJS.extend(customParseFormat)
DayJS.extend(isSameOrBefore)
DayJS.extend(isSameOrAfter)


export default DayJS
