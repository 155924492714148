import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Styles from './SpreadMessage.module.css'
import { Tabs } from '../../../../../components/Buttons/Tabs'
import { TextMessage } from './Mode/TextMessage'
import { UploadVideo } from './Mode/UploadVideo'
import { useContent } from '../../../../../hooks/useContent'
import { useTeam } from '../../../Hooks/useTeam'
import { useTranslation } from 'react-i18next'
import { useUIContext } from '../../../../../context/UIContext'

export const SpreadMessageModal = () => {
    const { t } = useTranslation('teamMonitor')
    const { closeModal } = useUIContext()
    const { courseID } = useTeam()
    const { isLoading, sendContent } = useContent()
    const [type, setType] = useState<Type>('video')

    const srcState = useState('')
    const [src] = srcState

    const messageState = useState('')
    const [message, setMessage] = messageState

    if (!courseID) return null

    const disabled = (!src && !message) || isLoading

    const tabs = (['video', 'text'] as const).map((value) => ({
        label: t(value),
        value,
    }))

    return (
        <section className={Styles.section}>
            <header className={Styles.header}>
                <span className={Styles.title}>{t('Spread message')}</span>
                <button style={{ border: 0, background: 0 }} onClick={closeModal}>
                    <FontAwesomeIcon icon={['far', 'times']} />
                </button>
            </header>
            <Tabs tabs={tabs} defaultSelected={type} onChange={setType} />
            <hr />
            <main>
                <form
                    onSubmit={(event) => {
                        event.preventDefault()

                        if (!src && !message) return

                        sendContent({
                            filter: {
                                type: 'any',
                                to: [courseID],
                            },
                            payload: type === 'video' ? { type, src } : { type, message },
                        })
                        setMessage('')
                    }}
                >
                    <div className={Styles.selectedTab}>
                        {type === 'video' ? (
                            <UploadVideo state={srcState} />
                        ) : (
                            <TextMessage state={messageState} />
                        )}
                    </div>

                    <button className="primary" disabled={disabled}>
                        {t('Send now')}
                    </button>
                </form>
            </main>
        </section>
    )
}

export const SpreadMessageModalStyles: {
    content: React.CSSProperties
    overlay: React.CSSProperties
} = {
    content: {
        width: 'min-content',
        height: 'min-content',
        minWidth: '24.125rem',
    },
    overlay: {
        backgroundColor: 'rgba(0, 55, 80, 0.98)',
    },
}

type Type = 'video' | 'text'
