import React, { ReactNode } from 'react'
import TeamReport from '../../../../../pages/Team/Components/Actions/TeamReport'
import { ButtonExport } from '../../../../../pages/Team/Reports/Components/ButtonExport'
import { useTeam } from '../../../../../pages/Team/Context/TeamContext'
import { useTranslation } from 'react-i18next'
import { useUIContext } from '../../../../../context/UIContext'
import {
    HeaderBannerContainer,
    HeaderBannerContent,
    HeaderBannerTitle,
    HeaderBannerSubtitle,
} from './HeaderBanner.styles'

const HeaderBanner = ({
    actions = '',
    title = '',
    subtitle = '',
    subActions = '',
}: IHeaderBannerProps) => {
    return (
        <>
            <HeaderBannerContainer>
                <HeaderBannerTitle>{title}</HeaderBannerTitle>
                <HeaderBannerContent>{actions}</HeaderBannerContent>
            </HeaderBannerContainer>
            <HeaderBannerContent>
                <HeaderBannerSubtitle>{subtitle}</HeaderBannerSubtitle>
                <HeaderBannerContent>{subActions}</HeaderBannerContent>
            </HeaderBannerContent>
        </>
    )
}

export default HeaderBanner

export interface IHeaderBannerProps {
    actions?: ReactNode
    title?: string
    subtitle?: string
    subActions?: ReactNode
}
