import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { useLocation } from 'wouter'
import { BtnCircular, BtnCircularProps, BtnIcon } from '../../../../components/Buttons/BtnCircular'
import ConfirmModal from '../../../../components/ConfirmModal'
import { useUIContext } from '../../../../context/UIContext'
import { useGAEvents } from '../../../../hooks/useGAEvents'
import { colors } from '../../../../styles/globals/_variables'
import { deleteStudent, GetTeamDetails, StudentOrMentor } from '../../../../apis/api.signup'
import { useTeam } from '../../Context/TeamContext'
import { ActionsSelector } from './Actions/ActionsSelector'
import Styles from './StudentItem.module.css'

export const StudentItem = (props: StudentItemProps) => (
    <div
        className={`user-item ${Styles.studentItem} ${
            props?.className ? Styles[`${props?.className}`] : ''
        }`}
    >
        <StudentInfo {...props} />
        <ActionsSelector {...props} />
        <MoreOptions {...props} />
    </div>
)

const StudentInfo = (props: StudentItemProps) => (
    <div className={Styles.studentInfo}>
        {props.team?.position && (
            <div className={Styles.position}>{props.team?.position || '-'}</div>
        )}
        <Avatar {...props} />
        <Label {...props} />
        <TeamBadge {...props} />
    </div>
)

const Avatar = (props: StudentItemProps) => {
    const { t } = useTranslation('teamManager')
    const { team } = useTeam()
    const [, setLocation] = useLocation()
    if (!team) return null
    const { professorSchedule, subject } = team

    const {
        urlImage,
        _profileId,
        displayName,
        isMentor,
        isExpertMentor,
        isFacilitator,
        hasVoted,
        mentorType,
    } = props

    const profileUrl = `/team/${professorSchedule._id}/subject/${subject._id}/profile/${_profileId}`

    const size = 62

    return (
        <div className={Styles.avatar}>
            <div>
                <div onClick={() => setLocation(profileUrl)} style={{ position: 'relative' }}>
                    {urlImage ? (
                        <img
                            src={urlImage}
                            alt={displayName}
                            title={displayName}
                            style={{ width: size, height: size, borderRadius: '100%' }}
                        />
                    ) : (
                        <BtnCircular
                            icon={['fal', 'user-circle']}
                            title={displayName}
                            style={{
                                color: '#dadada',
                                width: size,
                                height: size,
                            }}
                        />
                    )}
                    <div className={Styles.avatarBadges}>
                        {(isMentor || isExpertMentor) && (
                            <BtnIcon
                                icon={'graduation-cap'}
                                style={{
                                    fontSize: 13,
                                    backgroundColor: isExpertMentor
                                        ? 'rgb(123, 0, 145)'
                                        : 'rgb(162, 21, 213)',
                                    border: '1px solid white',
                                    width: 28,
                                    height: 28,
                                }}
                                toolTips={
                                    isExpertMentor
                                        ? t('mentor.expert')
                                        : mentorType === 'guide'
                                        ? t('mentor.guide')
                                        : t('mentor.mentor')
                                }
                            />
                        )}

                        {isFacilitator && (
                            <BtnIcon
                                icon={'medal'}
                                style={{
                                    fontSize: 15,
                                    backgroundColor: 'rgb(255, 137, 0)',
                                    border: '1px solid white',
                                    width: 28,
                                    height: 28,
                                }}
                                toolTips={t('facilitator.title')}
                            />
                        )}
                        {hasVoted && (
                            <BtnIcon
                                icon={'bolt'}
                                toolTips={t('stats.recognitions.given')}
                                style={{
                                    fontSize: 15,
                                    background: 'linear-gradient(#ff7f7f 0%, #00aaff 100%)',
                                    border: '1px solid white',
                                    width: 28,
                                    height: 28,
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

const Label = (props: StudentItemProps) => {
    const { team } = useTeam()
    const { t } = useTranslation('teamManager')
    const [, setLocation] = useLocation()
    if (!team) return null
    const { professorSchedule, subject } = team
    const { displayName, _profileId, isMentor, isExpertMentor, mentorType, isFacilitator } = props
    const profileUrl = `/team/${professorSchedule._id}/subject/${subject._id}/profile/${_profileId}`

    return (
        <div className={Styles.label} style={{ fontSize: 16, color: colors.darkGreyEgg }}>
            <span onClick={() => setLocation(profileUrl)}>
                {displayName}

                {(isMentor || isExpertMentor) && (
                    <div className={Styles.mentorLabel}>
                        {isExpertMentor
                            ? t('mentor.expert')
                            : mentorType === 'guide'
                            ? t('mentor.guide')
                            : t('mentor.mentor')}
                    </div>
                )}

                {isFacilitator && (
                    <div className={Styles.mentorLabel}>{t('facilitator.title')}</div>
                )}
            </span>
        </div>
    )
}

const TeamBadge = (props: StudentItemProps) => {
    const { t } = useTranslation('teamManager')
    const { showTeamBadge, team } = props

    if (!showTeamBadge) return null

    if (team?.number)
        return (
            <div className={Styles.assignedBadge}>
                {t('search.badge-team')} {team.number}
            </div>
        )
    else return <div className={Styles.unAssignedBadge}>{t('search.badge-unassigned')}</div>
}

const MoreOptions = (props: StudentItemProps) => {
    const queryClient = useQueryClient()
    const [modalDeleteUser, setModalDeleteUser] = useState(false)
    const { t } = useTranslation('teamManager')
    const { toastError, toastNotification } = useUIContext()
    const { displayName, _profileId, canDelete, isAssigned, team: teamInfo } = props
    const { unassigned, studentItemMoreOptionState, courseID, unassignedPage } = useTeam()
    const { sendGA } = useGAEvents()

    const [state, setState] = studentItemMoreOptionState

    const { mutate: onDelete } = useMutation(
        [courseID, 'Team', 'Delete student', _profileId],
        () => deleteStudent(`${courseID}`, `${_profileId}`),
        {
            onMutate: () => {
                sendGA({
                    category: 'Team Management',
                    action: 'DeleteStudentClicked',
                })

                if (!isAssigned) {
                    // Remove from unassigned list
                    const key = [courseID, 'Team', 'Unassigned', unassignedPage]
                    const oldUnassigned = queryClient.getQueryData<typeof unassigned>(key)

                    queryClient.setQueryData(key, {
                        ...oldUnassigned,
                        results:
                            !!oldUnassigned?.results?.length &&
                            oldUnassigned?.results.filter(
                                (student) => student._profileId !== _profileId
                            ),
                    })
                } else {
                    // Or remove from team if is assigned
                    const teamNumber = teamInfo?.number
                    if (!teamNumber) return
                    const key = [courseID, 'Team', 'Details', teamNumber]
                    const oldTeam = queryClient.getQueryData<TeamDetails>(key)

                    oldTeam &&
                        queryClient.setQueryData(key, {
                            ...oldTeam,
                            students:
                                !!oldTeam?.students?.length &&
                                oldTeam?.students.filter(
                                    (student) => student._profileId !== _profileId
                                ),
                        })
                }
            },
            onError: (error) => {
                const key = isAssigned
                    ? [courseID, 'Team', 'Details', teamInfo?.number]
                    : [courseID, 'Team', 'Unassigned', unassignedPage]

                queryClient.invalidateQueries(key)
                queryClient.invalidateQueries([courseID, 'Team', 'Search', 'people'], {
                    exact: false,
                })
                setModalDeleteUser(false)
                toastError(error)
            },
            onSuccess: () => {
                const message = t('teamManager:actions.delete.toast_message', { displayName })
                queryClient.invalidateQueries([courseID, 'Team', 'Search', 'people'], {
                    exact: false,
                })
                setModalDeleteUser(false)
                toastNotification(message, 'success')
            },
        }
    )

    // const profileUrl = `/team/${team?.professorSchedule._id}/subject/${team?.subject._id}/profile/${_profileId}`
    const isOpen = state === _profileId

    const handleClick = () => {
        if (!isOpen || null) setState(_profileId || null)
        else setState(null)
    }

    const options: BtnCircularProps['options'] = [
        // {
        //     label: t('user-options.profile'),
        //     onClick: () => (location.href = profileUrl),
        // },
        {
            label: t('user-options.delete'),
            icon: 'trash-alt',
            onClick: () => setModalDeleteUser(true),
            props: {
                style: { color: colors.danger, fontWeight: 'bold' },
            },
        },
    ]

    !canDelete && options.pop() // Remove delete user option

    return (
        <div className="moreOptions">
            <BtnCircular
                icon="ellipsis-h"
                style={{
                    marginLeft: '0.5em',
                    width: 32,
                    height: 32,
                    fontSize: 15,
                    borderColor: colors.mediumGreyBg,
                    background: 'white',
                }}
                isOpen={isOpen}
                onClick={() => handleClick()}
                options={options}
            />
            <ConfirmModal
                onConfirm={onDelete}
                onClose={() => setModalDeleteUser(false)}
                open={modalDeleteUser}
                type={'danger'}
                title={t('user-options.title-modal')}
                contentText={t('user-options.content-modal', { displayName })}
                confirmButtonText={t('user-options.confirm-btn-modal')}
                cancelButtonText={t('user-options.cancel-btn-modal')}
            />
        </div>
    )
}

export const inputStyles: React.CSSProperties = {
    background: 'white',
    border: '1px solid #eaeaea',
    borderRadius: 5,
    color: '#073045',
    fontWeight: 'bold',
    height: 42,
    margin: 0,
    outline: 0,
    padding: 15,
}

// Interface
export type StudentItemProps = StudentOrMentor & {
    team?: {
        number: number
        position?: number
    }
    tableNumber?: number
    canDelete?: boolean
    showTeamBadge?: boolean
    algorithmType?: 'default' | 'divideByGroup' | 'defaultByGroup' | 'hca4' | 'hca1'
    className?: string
}

type TeamDetails = GetTeamDetails['data']['teams'][0]
