import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import LoadingPage from '../../../Other/LoadingPage'
import { getNPSCsv } from '../../api-reports'
import { OneData } from '../../Components/Monitor/Cards/OneData'
import { ProgressBars, ProgressProps } from '../../Components/Monitor/Cards/ProgressBars'
import { useCsvReport } from '../../Hooks/useCsvReport'
import { useSurveys } from '../../Hooks/useSurveys'
import { ButtonExport } from './ButtonExport'
import { NoData } from './ChartComponent'

export const SurveysReports = () => {
    const { t } = useTranslation('teamReports')
    const { getFilteredReport } = useCsvReport()

    const { graphNPS, isLoading } = useSurveys()

    const {
        promoters,
        promotersPercentage,
        cohortSize,
        passives,
        passivesPercentage,
        detractors,
        detractorsPercentage,
        nps,
        totalResponses,
    } = graphNPS || {}

    const npsCardData: ProgressProps[] = nps
        ? [
              {
                  icon: ['far', 'circle-check'],
                  caption: t('promoters'),
                  value: promoters,
                  percentage: promotersPercentage,
                  variant: 'egg',
              },
              {
                  icon: ['far', 'circle-play'],
                  caption: t('passives'),
                  value: passives,
                  percentage: passivesPercentage,
                  variant: 'info',
              },
              {
                  icon: ['far', 'circle-exclamation'],
                  caption: t('detractors'),
                  value: detractors,
                  percentage: detractorsPercentage,
                  variant: 'danger',
              },
          ]
        : []

    if (isLoading) return <LoadingPage />
    return (
        <SurveysReportsStyled>
            <header className="report-header">
                <h4>Net Promoter Score (NPS)</h4>
                <ButtonExport
                    onClick={() => getFilteredReport(getNPSCsv, 'nps')}
                    disabled={!graphNPS}
                />
            </header>
            {!graphNPS ? (
                <NoData />
            ) : (
                <div className="report-display">
                    <OneData
                        title={`${t('Cohort Size')}: ${cohortSize}`}
                        caption=""
                        data={nps}
                        footerIcon={undefined}
                        footerData={undefined}
                        footerText={undefined}
                    />
                    <ProgressBars
                        title={`${t('Number of responses', { totalResponses })}`}
                        metrics={npsCardData}
                    />
                </div>
            )}
        </SurveysReportsStyled>
    )
}

const SurveysReportsStyled = styled.div`
    & > .report-display {
        display: grid;
        grid-template-columns: 25% 75%;
        gap: 1em;
        & > section {
            display: flex;
            flex-direction: column;
            justify-content: center;
            & > * {
                border-bottom: none;
            }
        }
    }
`
