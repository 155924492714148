import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'

type Props = {
    onClick: () => void
    disabled: boolean
    label?: string
}

export const ButtonExport = ({ onClick, disabled, label }: Props) => {
    const { t } = useTranslation('teamReports')
    return (
        <ButtonStyles onClick={onClick} disabled={disabled}>
            <FontAwesomeIcon
                className="icon"
                style={{ fontSize: 14 }}
                icon={['far', 'arrow-up-right-from-square']}
            />
            {label ?? t('export report')}
        </ButtonStyles>
    )
}

const ButtonStyles = styled.button`
    height: 36px;
    min-width: 160px;
    padding: 1em;
    background-color: #2b75ff;
    color: white;
    font-size: 13px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border: 0px;
    border-radius: 8px;
    cursor: pointer;
    &:hover {
        background-color: #105df9;
    }
`
