import { ConfirmMeetingChange, SuccessModal } from './ConfirmMeetingChange'

import DayJS from '../../../../../helpers/DayJS'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import i18n from '../../../../../i18n'
import { useRotate } from '../../../../../hooks/useRotate'
import { useState } from 'react'
import { useTeam } from '../../../Hooks/useTeam'
import { useTimeline } from '../../../Hooks/useTimeline'
import { useTranslation } from 'react-i18next'

export const MoveToTeamsModal = ({ keepsTeam }: { keepsTeam: boolean }) => {
    const { t } = useTranslation('teamMonitor')
    const { courseID } = useTeam()
    const { rotateAsync, isRotating } = useRotate()
    const { moveToTeams, isMutating } = useTimeline()
    const [isSuccess, setIsSuccess] = useState(false)

    if (!courseID) return null

    const duration = [1, 'minute'] as const
    const diff = DayJS()
        .add(...duration)
        .diff(DayJS())
    const time = DayJS.duration(diff).locale(i18n.resolvedLanguage).humanize(true)

    const sendToTeams = async () => {
        moveToTeams()
        setIsSuccess(true)
    }

    if (isRotating || isMutating)
        return (
            <FontAwesomeIcon
                icon={['far', 'loader']}
                spin
                style={{
                    position: 'relative',
                    width: '2.8rem',
                    height: '2.5rem',
                    display: 'block',
                    margin: 'auto',
                    top: 'calc(50% - 1rem)',
                }}
            />
        )

    if (isSuccess)
        return (
            <SuccessModal
                text={
                    <p>
                        {keepsTeam
                            ? t('Participants will be sent to the team meeting')
                            : t('Participants will be assigned to new teams')}{' '}
                        <b>{time}</b>
                    </p>
                }
            />
        )

    if (keepsTeam)
        return (
            <ConfirmMeetingChange
                icon={<FontAwesomeIcon icon={['far', 'circle-arrow-right']} />}
                text={t('Participants will be sent to the team meeting')}
                onConfirm={sendToTeams}
            />
        )

    return (
        <ConfirmMeetingChange
            icon={<FontAwesomeIcon icon={['fas', 'rotate']} />}
            text={<>{t('Participants will be assigned to new teams')}.</>}
            onConfirm={() => rotateAsync({ courseID }).then(() => setIsSuccess(true))}
        />
    )
}
