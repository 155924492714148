// Menu.component.jsx
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import TabMenu, {
    ActionTitles,
} from '../../../../components/DesignSystem/components/molecules/TabMenu/TabMenu.component'
import { ValuationReports } from './ValuationReports'
import { AttendancesReports } from './AttendancesReports'
import { CooperationReports } from './CooperationReports'
import { SurveysReports } from './SurveysReports'
import { UsersReports } from './UsersReports'
import { FilterHeader } from './FilterHeader'
import { ReportsActions, useReports } from '../../Context/ReportsContext'
import { useTeam } from '../../Context/TeamContext'

export const Menu = () => {
    const { t } = useTranslation('teamReports')
    const { team } = useTeam()
    const { reportMenuTab } = useReports()
    const [actions, setActions] = reportMenuTab

    const handleKeyPress = (event: KeyboardEvent) => {
        const { target, key } = event
        if (target !== document.body || typeof key !== 'string') return

        const keyToLower = key.toLocaleUpperCase()

        switch (keyToLower) {
            case '1':
                setActions('users')
                break
            case '2':
                setActions('participation')
                break
            case '3':
                setActions('valuation')
                break
            case '4':
                setActions('surveys')
                break
            case '5':
                setActions('cooperation')
                break
        }
    }

    const customClassName = (action: string) => (actions === action ? 'selected' : '')

    const actionTitles: ActionTitles = {
        users: {
            disabled: false,
            shortcut: '1',
            title: t('users'),
            component: <UsersReports />,
        },
        attendance: {
            disabled: false,
            shortcut: '2',
            title: t('participation'),
            component: <AttendancesReports />,
        },
        valuation: {
            disabled: false,
            shortcut: '3',
            title: t('valuation'),
            component: <ValuationReports />,
        },
        comments: {
            disabled: false,
            shortcut: '4',
            title: t('surveys'),
            component: <SurveysReports />,
        },
        cooperation: {
            disabled: false,
            shortcut: '5',
            title: t('cooperation'),
            component: <CooperationReports />,
        },
    }

    const tabsActions = (action: ReportsActions) => ({
        children: actionTitles[action]?.title,
        className: customClassName(action),
        disabled: actionTitles[action]?.disabled,
        onClick: () => setActions(action),
        title: `[${actionTitles[action]?.shortcut}] ${actionTitles[action]?.title}`,
    })

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const actionsArray = Object.keys(actionTitles).map((action: any) => tabsActions(action))

    useEffect(() => {
        document.addEventListener('keyup', handleKeyPress)
        return () => document.removeEventListener('keyup', handleKeyPress)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const activeComponent = actionTitles[actions].component

    if (!team) return null

    return (
        <MenuStyles>
            <TabMenu actions={actionsArray} />
            {reportMenuTab[0] !== 'users' && <FilterHeader />}
            {activeComponent}
        </MenuStyles>
    )
}

const MenuStyles = styled.div`
    display: flex;
    flex-direction: column;
    & > .tab-menu {
        margin-bottom: 3rem;
    }
    & .report-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 2em auto 1em auto;
    }
    & > section > .report-content {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2rem;
        & .chart {
            width: '100%';
            & > * {
                text-transform: capitalize;
            }
            svg {
                border-radius: 25px;
            }
        }
    }
    @media (max-width: 1140px) {
        & > section > .report-content {
            flex-direction: column;
        }
    }
`
