import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import HeaderBanner from '../../components/DesignSystem/components/molecules/HeaderBanner/HeaderBanner.component'
import WarningBlock from '../../components/WarningBlock'
import { useUser } from '../../context/UserContext'
import { capitalize } from '../../helpers/formatter'
import { useGAEvents } from '../../hooks/useGAEvents'
import FullLayout from '../../layouts/FullLayout'
import LoadingPage from '../Other/LoadingPage'
import { useTeam } from './Context/TeamContext'
import { useCohorts } from './Hooks/useCohorts'
import { NoData } from './Reports/Components/ChartComponent'
import { CohortsSelect } from './Reports/Components/CohortsSelect'
import { Menu } from './Reports/Components/Menu'

export const Reports = () => {
    const { role } = useUser()
    const { team, courseID, mainLinks } = useTeam()
    const { sendGA } = useGAEvents()

    useEffect(() => {
        sendGA({ category: 'Reporting', action: 'ViewReportSection' })
    }, [team, role, sendGA])

    useEffect(() => {
        sessionStorage.setItem('last-courseId', courseID || '')
    }, [courseID])

    if (!team) return null

    const {
        hq: { name: hqName },
        teamName,
    } = team

    const Content = () => {
        if (!process.env.REACT_APP_REPORTS)
            return (
                <p style={{ textAlign: 'center' }} className="danger">
                    Environment Variable REACT_APP_REPORTS required !
                </p>
            )
        return (
            <>
                <WarningBlock />
                <ReportsContent />
            </>
        )
    }

    return (
        <FullLayout links={mainLinks} ruta={`${capitalize(hqName)} > ${capitalize(teamName)}`}>
            <Content />
        </FullLayout>
    )
}

export default Reports

const ReportsContent = () => {
    const { t } = useTranslation('teamReports')
    const { team } = useTeam()
    const { cohorts, isLoading } = useCohorts()

    if (isLoading) return <LoadingPage />

    return (
        <Report className="container">
            <HeaderBanner title={team?.teamName} subtitle={t('reports')} />
            {!cohorts ? (
                <NoData text={`You don't have cohorts yet`} />
            ) : (
                <>
                    <CohortsSelect />
                    <Menu />
                </>
            )}
        </Report>
    )
}

const Report = styled.section`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1rem 0;
    h2 {
        margin: 0;
    }
    & .loading-page-container {
        height: 6em;
    }
    & .cohort-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`

export const colors = {
    green: '#15C0AF',
    grey: '#383A40',
    lightGrey: '#D8DADB',
    lightBlue: '#00A3FF',
    violet: '#BF03E2',
    red: '#FF647C',
    yellow: '#ffcd00',
    blue: '#1A4B62',
    eggBlue: '#003750',
}
