import {
    GetTimelineResponse,
    TimelineEventDTO,
    getTimeline,
    newTimelineEvent as mutationFn,
    updateTimelineEvent as updateMutationFn,
} from '../../../apis/api.signup'
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useMutation, useQuery, useQueryClient } from 'react-query'

import DayJS from '../../../helpers/DayJS'
import { useTeam } from './useTeam'

export const useTimeline = () => {
    const { courseID, algorithmID, hasGeneral, isLoading } = useTeam()
    const queryKey = ['Team', 'Timeline', courseID] as const
    const queryClient = useQueryClient()

    const { data, ...rest } = useQuery({
        queryKey,
        queryFn: () => getTimeline(courseID!),
        enabled: !!courseID,
    })

    const { mutateAsync: newTimelineEvent, isLoading: isMutating } = useMutation({
        mutationKey: queryKey,
        mutationFn,
        onMutate: ({ date, room }) => {
            const snapshot = queryClient.getQueryData<GetTimelineResponse>(queryKey)

            const nextEvent: TimelineEventDTO = {
                date,
                room,
                _algorithmId: algorithmID,
                status: 'future',
                settings: {
                    enabledMic: true,
                    enabledShareScreen: true,
                },
            }

            if (snapshot)
                queryClient.setQueryData<GetTimelineResponse>(queryKey, (old) =>
                    old
                        ? {
                              ...old,
                              timeline: old.timeline.concat(nextEvent),
                              currentEvent:
                                  old.timeline.length === 0 ? nextEvent : old.timeline.at(-1),
                              nextEvent,
                          }
                        : snapshot
                )

            return snapshot
        },
        onSuccess: () => queryClient.invalidateQueries(queryKey),
        onError: (_error, _variables, snapshot) => {
            snapshot && queryClient.setQueryData(queryKey, snapshot)
        },
    })

    const { mutate: updateTimelineEvent } = useMutation({
        mutationKey: queryKey,
        mutationFn: updateMutationFn,
        onMutate: ({ event }) => {
            const snapshot = queryClient.getQueryData<GetTimelineResponse>(queryKey)

            snapshot &&
                queryClient.setQueryData(queryKey, {
                    ...snapshot,
                    currentEvent: {
                        ...snapshot.currentEvent,
                        ...event,
                        settings: {
                            ...snapshot.currentEvent?.settings,
                            ...event.settings,
                        },
                    },
                })

            return snapshot
        },
        onSuccess: () => queryClient.invalidateQueries(queryKey),
        onError: (_error, _variables, snapshot) => {
            snapshot && queryClient.setQueryData(queryKey, snapshot)
        },
    })

    const moveToTeams = () => {
        const courseID = sessionStorage.getItem('last-courseId')
        if (!courseID) return null

        const duration = [1, 'minute'] as const

        newTimelineEvent({
            courseID,
            room: 'teams',
            date: DayJS()
                .add(...duration)
                .toISOString(),
        }).then(() => {
            const room = hasGeneral ? 'general' : null
            const date = sessionStorage.getItem('teams-meeting-end-date')

            if (!date) return
            newTimelineEvent({
                courseID,
                room,
                date,
            })

            sessionStorage.removeItem('teams-meeting-end-date')
        })
    }

    return {
        ...data,
        ...rest,
        isMutating,
        newTimelineEvent,
        moveToTeams,
        updateTimelineEvent,
        isLoading: isLoading || rest.isLoading,
    }
}
