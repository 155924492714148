import React, { useEffect } from 'react'

import FullLayout from '../../layouts/FullLayout'
import { OverviewHeader } from './Components/Overview/OverviewHeader'
import WarningBlock from '../../components/WarningBlock'
import { capitalize } from '../../helpers/formatter'
import { useTeam } from './Context/TeamContext'

const Monitor = React.lazy(() => import('./Components/Monitor/CourseMonitor'))
const DistributionByContent = React.lazy(
    () => import('./Components/Overview/DistributionByContent')
)

export const Overview = () => {
    const { team, courseID, mainLinks } = useTeam()

    useEffect(() => {
        sessionStorage.setItem('last-courseId', courseID || '')
    }, [courseID])

    if (!team) return null
    const {
        hq: { name: hqName },
        teamName,
    } = team

    return (
        <FullLayout ruta={`${capitalize(hqName)} > ${capitalize(teamName)}`} links={mainLinks}>
            <WarningBlock />
            <div
                className="team-detail-container"
                style={{
                    backgroundColor: '#F8F8F8',
                    minHeight: 'inherit',
                }}
            >
                <div className="container overview">
                    <OverviewHeader />

                    <Monitor />

                    <DistributionByContent />
                </div>
            </div>
        </FullLayout>
    )
}
