import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import LoadingPage from '../../../Other/LoadingPage'
import { getAttendanceCsv, getAudioParticipationCsv, getParticipationCsv } from '../../api-reports'
import { useAttendance } from '../../Hooks/useAttendance'
import { useCsvReport } from '../../Hooks/useCsvReport'
import { useParticipation } from '../../Hooks/useParticipation'
import { colors } from '../../Reports'
import { ButtonExport } from './ButtonExport'
import { ChartComponent } from './ChartComponent'
import { getMaxValuePlusPercent } from './ValuationReports'

export const AttendancesReports = () => {
    const { t } = useTranslation('teamReports')
    const { getFilteredReport } = useCsvReport()

    const { attendances: graphAttendances, isLoading: isLoadingAttendances } = useAttendance()
    const { barChart: attendances, totalPieChart: totalAttendances } = graphAttendances ?? {}
    const { participation } = useParticipation()
    const { lineChart, audioLineChart } = participation ?? {}

    const { yellow, red, grey, lightBlue, green } = colors

    const transformAttendances = () => {
        if (!attendances) return null
        const columnNames = ['instance', 'assistants', 'absents', 'churns', 'totals']

        const header = columnNames.flatMap((name, index) =>
            index === 0
                ? [name]
                : [
                      t(name),
                      {
                          role: 'annotation',
                          type: name === 'totals' ? 'number' : 'string',
                          calc: 'stringify',
                      },
                  ]
        )

        const transformedData = (attendances as DataEntry[]).slice(1).map((row: DataEntry) => {
            const [instance, actives, absents, churns] = row
            const numberRow = row.slice(1).map((value) => value as number)
            const totals = numberRow.reduce((acc, value) => acc + value, 0)
            const maxHeight = getMaxValuePlusPercent(attendances)

            return [
                instance,
                actives,
                actives,
                absents,
                absents,
                churns,
                churns,
                maxHeight - totals, // Alto de la columna de totales
                totals,
            ]
        })
        return [header, ...transformedData]
    }

    const attendancesData = transformAttendances()

    const getMaxSecondElement = (): number | null => {
        if (!audioLineChart) return null
        const secondElements = audioLineChart.slice(1).map((item) => item[1]) as number[]
        return Math.max(...secondElements) + 10
    }

    if (isLoadingAttendances) return <LoadingPage />

    return (
        <AttendanceReportsStyles>
            <header className="report-header">
                <h4>{t('Attendance')}</h4>
                <ButtonExport
                    onClick={() => getFilteredReport(getAttendanceCsv, 'attendances')}
                    disabled={!attendances}
                />
            </header>
            <div className="report-display">
                <ChartComponent
                    chartType="ColumnChart"
                    data={attendancesData}
                    height={'500px'}
                    options={{
                        legend: { position: 'top' },
                        isStacked: true,
                        colors: [yellow, lightBlue, red, 'transparent'],
                        bar: { groupWidth: '50' },
                        chartArea: {
                            left: 70,
                            top: 70,
                            right: 40,
                            bottom: 70,
                        },
                        series: {
                            3: {
                                visibleInLegend: false,
                                annotations: {
                                    textStyle: {
                                        fontSize: 16,
                                        bold: true,
                                        color: 'black',
                                    },
                                },
                            },
                        },
                        theme: {
                            annotations: {
                                textStyle: { fontSize: 14 },
                            },
                        },
                        hAxis: {
                            slantedText: true,
                        },
                        vAxis: {
                            viewWindow: {
                                max: attendances && getMaxValuePlusPercent(attendances),
                            },
                        },
                    }}
                />
                <ChartComponent
                    chartType="PieChart"
                    data={totalAttendances ?? null}
                    width={'70%'}
                    options={{
                        title: t('Percentage attendances over actives'),
                        titleTextStyle: {
                            fontSize: 16,
                        },
                        colors: [yellow, lightBlue, grey],
                        chartArea: {
                            left: 40,
                            top: 70,
                            right: 40,
                            bottom: 20,
                        },
                        legend: { position: 'labeled' },
                    }}
                />
            </div>
            <header className="report-header">
                <h4>{t('% time in session')}</h4>
                <ButtonExport
                    onClick={() => getFilteredReport(getParticipationCsv, '%_time_in_session')}
                    disabled={!lineChart}
                />
            </header>
            <ChartComponent
                chartType="AreaChart"
                data={lineChart ?? null}
                options={{
                    legend: { position: 'none' },
                    colors: [yellow],
                    chartArea: {
                        left: 90,
                        top: 70,
                        right: 40,
                        bottom: 70,
                        width: '100%',
                        height: '100%',
                    },
                    vAxis: {
                        viewWindowMode: 'pretty',
                        viewWindow: {
                            max: 100,
                            min: 0,
                        },
                    },
                    hAxis: {
                        slantedText: true,
                    },
                }}
            />
            <header className="report-header">
                <h4>{t('% of time speaking')}</h4>
                <ButtonExport
                    onClick={() =>
                        getFilteredReport(getAudioParticipationCsv, '%_of_time_speaking')
                    }
                    disabled={!audioLineChart}
                />
            </header>
            <ChartComponent
                chartType="AreaChart"
                data={audioLineChart ?? null}
                options={{
                    legend: { position: 'none' },
                    colors: [green],
                    chartArea: {
                        left: 90,
                        top: 70,
                        right: 40,
                        bottom: 70,
                        width: '100%',
                        height: '100%',
                    },
                    vAxis: {
                        viewWindowMode: 'pretty',
                        viewWindow: {
                            max: getMaxSecondElement() ?? 30,
                            min: 0,
                        },
                    },
                    hAxis: {
                        slantedText: true,
                    },
                }}
            />
        </AttendanceReportsStyles>
    )
}

type DataEntry = [string, number, number, number]

const AttendanceReportsStyles = styled.section`
    & > .report-display {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr auto;
        gap: 1em;
        & > section {
            display: flex;
            flex-direction: column;
            justify-content: center;
            & > * {
                border-bottom: none;
            }
        }
    }
`
