/* eslint-disable react/prop-types */

import React, { useState, useContext } from 'react'
import MainLayout from '../../../../layouts/MainLayout'
import { useLocation } from 'wouter'

import AvatarEgg from '../../../../components/Styled/AvatarEgg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { UIContext } from '../../../../context/UIContext'
import { useTranslation } from 'react-i18next'

import './firepad.css'
import './codemirror.css'

const CollabEditView = (props) => {
    const {
        fpad,
        professorSchedule,
        onTitleSave,
        selectedDoc,
        mainLinks,
        downloadPdf,
        teamData,
    } = props

    const { t } = useTranslation(['teamCollab'])
    const [, setLocation] = useLocation()
    const [title, setTitle] = useState(
        selectedDoc && selectedDoc?.title ? selectedDoc.title : t('item.title')
    )
    const { titleCaseFn } = useContext(UIContext)
    const [titleFieldEditable, setTitleFieldEditable] = useState(false)

    const saveTitle = () => {
        onTitleSave(title ? title : t('item.title'))
        setTitleFieldEditable(false)
    }

    return (
        <MainLayout ruta="Collab" links={mainLinks}>
            <div className="collab-edit-heading">
                <div className="back-title-block">
                    <button
                        className="back-btn"
                        onClick={() =>
                            setLocation(
                                `/team/${professorSchedule.id}/subject/${teamData?._id}/collab/`
                            )
                        }
                    >
                        <FontAwesomeIcon icon={['fal', 'chevron-left']} />
                    </button>
                    <div className="title-container">
                        <input
                            disabled={!titleFieldEditable}
                            type="text"
                            onChange={(e) => setTitle(e.target.value)}
                            value={titleCaseFn(title)}
                        />
                    </div>
                    <button
                        className="btn-edit-title"
                        onClick={() =>
                            titleFieldEditable ? saveTitle() : setTitleFieldEditable(true)
                        }
                    >
                        {titleFieldEditable ? (
                            <FontAwesomeIcon icon={['fal', 'save']} />
                        ) : (
                            <FontAwesomeIcon icon={['fal', 'pencil']} />
                        )}
                    </button>
                </div>
                <div className="users-block">
                    <div id="userlist"></div>
                    <div className="avatars-container">
                        {selectedDoc &&
                            selectedDoc?.users?.map((user) => {
                                return (
                                    <AvatarEgg
                                        key={user._id}
                                        title={`${user.name} ${user.lastname}`}
                                        className="avatar"
                                        size={50}
                                        src={user.urlImage}
                                    />
                                )
                            })}
                    </div>
                    <div className="download-btn-block">
                        <button onClick={downloadPdf}>
                            {' '}
                            <FontAwesomeIcon icon={['fal', 'file-pdf']} />
                        </button>
                    </div>
                </div>
            </div>
            <div className="collab-page-container">
                <div className="editor-container" id="fpad-container" ref={fpad}></div>
            </div>
        </MainLayout>
    )
}

export default CollabEditView
