import {
    createContext,
    default as React,
    Dispatch,
    ReactNode,
    SetStateAction,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import {
    GetMentorsProps,
    getPeopleSearch,
    getTeam,
    getTeamDetails,
    GetTeamResponse,
    GetUnAssignedResponse,
    SaveExamsProps,
} from '../../../apis/api.signup'
import { ROLES } from '../../../constants'
import { useUser } from '../../../context/UserContext'
import { MenuProps } from '../../../layouts/NavBar/useNavBarItems'
import { MeetLauncher } from '../Components/MeetLauncher'
import { ModalMentors } from '../Components/ModalMentors'
import { OptionsSearchBox } from '../Components/SearchBox'
import { Team } from '../Team'

const Context = createContext({} as TeamContext)
Context.displayName = 'TeamContext'

export const useTeam = () => useContext<TeamContext>(Context)

export const TeamContextProvider = ({
    courseID,
    children = <Team />,
}: {
    courseID: string
    children?: ReactNode
}) => {
    const { t } = useTranslation('teamManager')
    const { role } = useUser()
    const [isSearching, setIsSearching] = useState<boolean>(false)
    const mentorsSearch = useState<GetMentorsProps | null>(null)
    const studentItemMoreOptionState = useState<string | null>(null)
    const actionsView = useState<ActionsView>('stats')
    const meeting = useState<string | null>(null)
    const examData = useState(initialExamData)
    const [pagination, setPagination] = useState({
        teams: 1,
        unassigned: 1,
    })

    /** Get Team list */
    const { data: team } = useQuery(
        [courseID, 'Team', 'Teams list', pagination.teams],
        () => getTeam(courseID, pagination.teams),
        {
            enabled: !!courseID,
            keepPreviousData: true,
        }
    )

    const mainLinks: MenuProps[] = [
        {
            name: t('main-links.room'),
            url: `/admin/overview/${courseID}`,
            active: window.location.pathname.includes('/admin/overview/'),
            icon: ['far', 'monitor-waveform'],
            activeIcon: ['far', 'monitor-waveform'],
        },
        {
            name: t('main-links.students'),
            url: `/admin/team/${courseID}`,
            active: window.location.pathname.includes('/admin/team/'),
            icon: ['far', 'people-group'],
            activeIcon: ['far', 'people-group'],
        },
    ]

    // Add reports menu
    role !== ROLES.PROFESSOR &&
        mainLinks.push({
            name: t('reports-section.title'),
            url: `/admin/reports/${courseID}`,
            active: window.location.pathname.includes('/admin/reports/'),
            icon: ['far', 'chart-mixed'],
            activeIcon: ['far', 'chart-mixed'],
        })
    // Add Setting menu
    role !== ROLES.PROFESSOR &&
        mainLinks.push({
            name: t('main-links.settings'),
            url: `/admin/settings/${courseID}`,
            active: window.location.pathname.includes('/admin/settings/'),
            icon: ['far', 'gear'],
            activeIcon: ['far', 'gear'],
        })

    /** Build SearchBox available search options */
    const searchOptions: OptionsSearchBox[] = useMemo(
        () => [
            {
                type: 'people',
                label: t('search.badge-people'),
                placeholder: t('search.option-placeholder-people'),
                icon: ['far', 'user'],
            },
            {
                type: 'teams',
                label: `${t('search.badge-team')} Nº`,
                placeholder: t('search.option-placeholder-teams'),
                icon: ['far', 'users'],
            },
        ],
        [t]
    )

    /** Populate search assistant list, with student names & team numbers */
    useEffect(() => {
        if (!team) return

        const indexPeople = searchOptions.findIndex((item) => item.type === 'people')
        if (indexPeople < 0) return

        const indexTeams = searchOptions.findIndex((item) => item.type === 'teams')
        if (indexTeams < 0) return
        searchOptions[indexTeams]['list'] = [...team.stats.allTeamNumbers]
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [team])
    return (
        <Context.Provider
            value={{
                team,
                courseID: team?.course._id,
                algorithmID: team?.algorithm?._id,
                mainLinks,
                actionsView,
                searchOptions,
                isSearching,
                setIsSearching,
                mentorsSearch,
                studentItemMoreOptionState,
                examData,
                meeting,
                teamPage: pagination.teams,
                unassignedPage: pagination.unassigned,
                setPagination,
            }}
        >
            {children}
            <MeetLauncher />
            <ModalMentors />
        </Context.Provider>
    )
}

export const initialExamData: ExamValues = {
    title: '',
    date: new Date(),
    notes: [],
}

// Interface
interface TeamContext {
    team?: GetTeamResponse['data']
    courseID?: string
    algorithmID?: string
    unassigned?: GetUnAssignedResponse['data']
    mainLinks: MenuProps[]
    actionsView: [ActionsView, Dispatch<SetStateAction<ActionsView>>]
    searchOptions: OptionsSearchBox[]
    isSearching: boolean
    setIsSearching: Dispatch<SetStateAction<boolean>>
    mentorsSearch: [GetMentorsProps | null, Dispatch<SetStateAction<GetMentorsProps | null>>]
    studentItemMoreOptionState: [string | null, Dispatch<SetStateAction<string | null>>]
    examData: [ExamValues, Dispatch<SetStateAction<ExamValues>>]
    meeting: [string | null, Dispatch<SetStateAction<string | null>>]
    teamPage: number
    unassignedPage: number
    setPagination: Dispatch<
        SetStateAction<{
            teams: number
            unassigned: number
        }>
    >
}

export type ActionsView = 'attendance' | 'stats' | 'rearrange'
export type SearchResults =
    | null
    | ReturnType<typeof getTeamDetails>
    | ReturnType<typeof getPeopleSearch>

interface ExamValues {
    title: string
    date: Date
    notes: SaveExamsProps['exam']['notes']
}
